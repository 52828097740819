.container {
  display: flex;
  background-image: url("../../assets/img/Bg1.jpg");
  background-repeat: no-repeat;
  background-size: 100vw auto;
  resize: horizontal;
  /* overflow-x: hidden; */
}
.flexColumn {
  flex-direction: column;
}
.containerNoBackground {
  flex: 1;
  display: flex;
}
.buttonWrapperBack {
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
}
.buttonWrapperNext {
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
}
.imgWrapper {
  flex: 0.6;
  display: flex;
  height: auto;
  width: auto;
  transition: 0.5s;
  align-items: center;
  justify-content: center;
}

.imgZoomInWrapper {
  flex: 1;
  display: flex;
  /* flex-direction: column; */
  height: auto;
  width: auto;
  /* max-height: 0vh; */
  /* transform: scale(2); */
  zoom: 2;
  transition: 0.5s;
  align-items: center;
  z-index: 999;
}

.button {
  height: 100px;
}

.ToolBarRight {
  position: fixed;
  z-index: 1001;
  right: 0;
}

.ToolBarLeft {
  position: fixed;
  z-index: 1001;
  left: 0;
}

.ToolBarTop {
  width: 100%;
  position: fixed;
  z-index: 1001;
  left: 0;
}

.ToolBarBottom {
  width: 100%;
  position: fixed;
  z-index: 1001;
  left: 0;
  bottom: 0;
}

.selectDisable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.tileViewContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: normal;
  padding: 60px;
}

.imageTileViewContainer {
  box-shadow: 12px 12px 29px -14px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 12px 12px 29px -14px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 12px 12px 29px -14px rgba(0, 0, 0, 0.58);
  border-width: 1px;
  border-style: solid;
  border-color: gray;
  margin-left: 5px;
  margin-bottom: 5px;
}

.imageTileView {
  width: 340px;
  cursor: pointer;
}

@media (max-width: 1500px) {
  .imgZoomInWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: auto;
    width: auto;
    zoom: 1.6;
    /* max-height: 0vh; */
    /* transform: scale(1.6); */
    transition: 0.5s;
    justify-content: center;
    align-items: center;
  }

  .imageTileView {
    width: 400px;
    cursor: pointer;
    transition: 0.5s;
  }
}

.modalContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.bookItem {
  margin: 14px;
  box-shadow: 1px 1px 6px 1px #3b3b3b;
}
.bookImage {
  width: auto;
  height: 40vh;
}
.separator {
  height: 1px;
  display: flex;
  margin: 8px 0px;
  align-self: center;
  width: calc(100% - 120px);
  background-color: black;
}
